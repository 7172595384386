<template>
  <div
    :is="name"
    :style="{
      width: size + 'px !important',
      height: size + 'px !important',
    }"
    :class="[
      'v-icon',
      {
        'v-icon_grey': color === 'grey',
        'v-icon_blue': color === 'blue',
        'v-icon_red': color === 'red',
      },
    ]"
  ></div>
</template>

<script>
import IconAttention from '@/assets/icons/attention.svg';
import IconMagnific from '@/assets/icons/magnific2.svg';
import IconAction from '@/assets/icons/action.svg';
import IconCheck from '@/assets/icons/check.svg';
import IconClose from '@/assets/icons/close.svg';
import IconPhone from '@/assets/icons/phone.svg';
import IconProjects from '@/assets/icons/projects.svg';
import IconUser from '@/assets/icons/user3.svg';
import IconUsers from '@/assets/icons/users.svg';
import IconMeet from '@/assets/icons/meet.svg';
import IconPlay from '@/assets/icons/play.svg';
import IconList from '@/assets/icons/list.svg';
import IconArrowTriangle from '@/assets/icons/arrow-triangle.svg';

import ArrowTriangleNew from '@/assets/svg/ArrowTriangleNew.svg';
import Calendar from '@/assets/svg/Calendar.svg';
import Close from '@/assets/svg/Close.svg';
import AlarmClock from '@/assets/svg/AlarmClock.svg';
import Check from '@/assets/svg/Check.svg';
import Taskdone from '@/assets/svg/Taskdone.svg';
import TaskdoneEmpty from '@/assets/svg/TaskdoneEmpty.svg';
import Avatar from '@/assets/svg/Avatar.svg';
import Search from '@/assets/svg/Search.svg';
import Call from '@/assets/svg/Call.svg';
import Action from '@/assets/svg/Action.svg';
import Meet from '@/assets/svg/Meet.svg';
import Attachment from '@/assets/svg/Attachment.svg';
import SubTask from '@/assets/svg/SubTask.svg';
import Settings from '@/assets/svg/Settings.svg';
import Setting from '@/assets/svg/Setting.svg';
import Analytics from '@/assets/svg/Analytics.svg';
import Chat from '@/assets/svg/Chat.svg';
import Project from '@/assets/svg/Project.svg';
import Arrows from '@/assets/svg/Arrows.svg';
import MainMenu from '@/assets/svg/MainMenu.svg';
import MenuDot from '@/assets/svg/MenuDot.svg';
import PlusAdd from '@/assets/svg/PlusAdd.svg';
import DragAndDrop from '@/assets/svg/DragAndDrop.svg';
import Save from '@/assets/svg/Save.svg';
import Inbox from '@/assets/svg/Inbox.svg';
import Trash from '@/assets/svg/Trash.svg';
import Warning from '@/assets/svg/Warning.svg';
import Warning2 from '@/assets/svg/Warning2.svg';
import Browser from '@/assets/svg/Browser.svg';
import ArrowLeft from '@/assets/svg/ArrowLeft.svg';
import ArrowRight from '@/assets/svg/ArrowRight.svg';
// просто Image писать нельзя - поэтому пишем IconImage
import IconImage from '@/assets/svg/Image.svg';
import Lock from '@/assets/svg/Lock.svg';
import LineSpacingDecrease2 from '@/assets/svg/LineSpacingDecrease2.svg';
import BackRightRepeat from '@/assets/icons/back-right-repeat.svg';

export default {
  name: 'VIcon',
  components: {
    IconAttention,
    IconMagnific,
    IconAction,
    IconCheck,
    IconClose,
    IconPhone,
    IconProjects,
    IconMeet,
    IconUser,
    IconUsers,
    IconPlay,
    IconList,
    IconArrowTriangle,

    ArrowTriangleNew,
    Calendar,
    Close,
    AlarmClock,
    Check,
    Taskdone,
    TaskdoneEmpty,
    Avatar,
    Search,
    Call,
    Action,
    Meet,
    Attachment,
    SubTask,
    Chat,
    Analytics,
    Settings,
    Setting,
    Project,
    Arrows,
    MainMenu,
    PlusAdd,
    MenuDot,
    DragAndDrop,
    Save,
    Inbox,
    Trash,
    Warning,
    Warning2,
    Browser,
    ArrowLeft,
    ArrowRight,
    IconImage,
    LineSpacingDecrease2,
    Lock,
    BackRightRepeat,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [Number, String],
      default: 22,
    },
    /**
     * @example 'grey' | 'blue' | 'red'
     */
    color: {
      type: String,
      default: 'grey',
    },
  },
}
</script>

<style lang="scss">
// 24, 22, 18, 14 SIZES
.dark-theme {
  .v-icon {
    &_grey {
      path {
        &:nth-child(odd) {
          fill: $darkThemeIconLightGrey
        }

        &:nth-child(even) {
          fill: $darkThemeIconGrey
        }
      }
    }

    &_blue {
      path {
        &:nth-child(odd) {
          fill: $darkThemeLightBlue
        }

        &:nth-child(even) {
          fill: $darkThemeBlue
        }
      }
    }
  }
}

.v-icon {
  display: block;

  &_grey {
    path {
      &:nth-child(odd) {
        fill: $lightGrey
      }

      &:nth-child(even) {
        fill: $grey
      }
    }
  }

  &_blue {
    path {
      &:nth-child(odd) {
        fill: $lightBlue
      }

      &:nth-child(even) {
        fill: $blue
      }
    }
  }

  &_red {
    path {
      fill: #F46C6C;
    }
  }
}
</style>

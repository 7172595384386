var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.name,{tag:"div",class:[
    'v-icon',
    {
      'v-icon_grey': _vm.color === 'grey',
      'v-icon_blue': _vm.color === 'blue',
      'v-icon_red': _vm.color === 'red',
    } ],style:({
    width: _vm.size + 'px !important',
    height: _vm.size + 'px !important',
  })})}
var staticRenderFns = []

export { render, staticRenderFns }